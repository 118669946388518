import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="ติดต่อ" />
      <div className="container mx-auto px-4 max-w-screen-sm">
        <section className="mt-4">
          <Search />
        </section>
        <section className="my-8">
          <h1 className="text-gray-700 text-xl">ติดต่อเรา</h1>
          <div className="text-gray-600 my-4">
            <p>
              ติดต่อสอบถาม แนะนำติชมได้ทาง
              <ul className="list-disc list-inside">
                <li>
                  Email{" "}
                  <a href="mailto:fundinfoplus@gmail.com">
                    fundinfoplus@gmail.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/%E0%B8%81%E0%B8%AD%E0%B8%87%E0%B9%81%E0%B8%A1%E0%B9%88com-107040114854478"
                    className="hover:text-blue-500"
                  >
                    เพจเฟซบุ๊ค
                  </a>
                </li>
                <li>กรอกแบบฟอร์มด้านล่าง</li>
              </ul>
            </p>
          </div>
          <hr className="border-gray-200" />
          <h2 className="text-gray-700 text-lg my-4">แบบฟอร์ม</h2>
          <script src="https://yastatic.net/s3/frontend/forms/_/embed.js"></script>
          <iframe
            src="https://forms.yandex.com/u/6066c81b63f1ee490f44336d/?iframe=1"
            frameBorder="0"
            name="ya-form-6066c81b63f1ee490f44336d"
            className="max-w-screen-sm w-full h-96"
            title="Contact Form"
          ></iframe>
        </section>
      </div>
    </Layout>
  );
};

export default ContactPage;
